import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Arrow from '../../images/installation/arrow.svg';

const useStyles = makeStyles(theme => ({
  title: {
    //fontSize: '20px',
    color: '#469B44',
    margin: 0,
    textTransform: 'uppercase',
  },
  ingress: {
    //fontSize: '20px',
    color: '#000000',
    margin: 0,
  },
  panelSummaryPadding: {
    padding: theme.spacing(0.5, 0),
  },
  panelReadMoreLink: {
    fontSize: '16px',
    color: '#000000',
    textDecoration: 'underline',
  },
  expansionPanelOnClose: {
    padding: theme.spacing(0, 2),
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#F2F4F6',
    },
  },
  expansionPanelOnOpen: {
    padding: theme.spacing(0, 2),
    boxShadow: 'none',

    backgroundColor: '#F2F4F6',
  },
  removePadding: {
    padding: 0,
  },
}));

type ManualExpansionPanelProps = {
  title: string;
  ingress: string;
  details: React.ReactNode;
};

const ManualExpansionPanel: React.FunctionComponent<ManualExpansionPanelProps> = ({ title, ingress, details }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <ExpansionPanel className={isOpen ? classes.expansionPanelOnOpen : classes.expansionPanelOnClose}>
      <ExpansionPanelSummary
        onClick={() => setIsOpen(!isOpen)}
        className={classes.removePadding}
        expandIcon={<img width="40" height="40" alt="" src={Arrow} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Grid container>
          <Grid item xs={10} sm={12} className={classes.panelSummaryPadding}>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.panelSummaryPadding}>
            <Typography variant="h4" className={classes.ingress}>
              {ingress}
            </Typography>
          </Grid>
        </Grid>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>{details}</ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default ManualExpansionPanel;
