import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Img from 'gatsby-image';
import { makeStyles } from '@material-ui/core/styles';
import { graphql, StaticQuery } from 'gatsby';
import ManualExpansionPanel from './ManualExpansionPanel';

const useStyles = makeStyles(theme => ({
  rowPadding: {
    padding: theme.spacing(2, 0),
  },
  boldText: {
    fontWeight: 'bold',
  },
  image: {
    borderRadius: theme.spacing(2),
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
  },
}));

const DruglistSection: React.FunctionComponent = () => {
  const classes = useStyles();
  return (
    <StaticQuery
      query={graphql`
        query {
          druglist1: file(relativePath: { eq: "installation/druglist1.png" }) {
            childImageSharp {
              fixed(width: 360, height: 640) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <ManualExpansionPanel
            title={'Lääkelista'}
            ingress={'Miten kerrytän omaa lääkelistaani?'}
            details={
              <Grid container>
                <Grid item xs={12} sm={12} md={6} className={classes.rowPadding}>
                  <Typography>
                    Lääkkeet, jotka ostat Treet-sovellusta tukevista apteekeista, listataan{' '}
                    <span className={classes.boldText}>Lääkkeeni</span>-sivulle, kun olet valinnut sovelluksen
                    asetuksista "Haluan ajantasaisen lääkelistan". Asetukset löydät{' '}
                    <span className={classes.boldText}>Lisää</span>-valikosta.
                  </Typography>
                  <Typography>
                    Lääkkeeni-sivun avulla näet helposti olennaiset tiedot resepteistäsi, ja voit myös tehdä ostoksia
                    kätevästi suoraan lääkelistaltasi.
                  </Typography>
                  <Typography>
                    Ostoksesi päivittyvät lääkelistallesi toimitustavasta riippumatta. Mikäli asioit apteekissa paikan
                    päällä, muista tunnistautua kassalla esimerkiksi henkilökortin avulla. Ostoksesi tulevat näkyviin
                    lääkelistallesi joko heti tai pienen viiveen jälkeen.
                  </Typography>
                </Grid>
                <Grid container justify="center" item xs={12} sm={12} md={6} className={classes.rowPadding}>
                  <Img className={classes.image} fixed={data.druglist1.childImageSharp.fixed} />
                </Grid>
              </Grid>
            }
          ></ManualExpansionPanel>
        );
      }}
    />
  );
};

export default DruglistSection;
