import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Img from 'gatsby-image';
import { makeStyles } from '@material-ui/core/styles';
import { graphql, StaticQuery } from 'gatsby';
import ManualExpansionPanel from './ManualExpansionPanel';

const useStyles = makeStyles(theme => ({
  rowPadding: {
    padding: theme.spacing(2, 0),
  },
  boldText: {
    fontWeight: 'bold',
  },
  image: {
    borderRadius: theme.spacing(2),
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
  },
}));

const ShoppingCartSection: React.FunctionComponent = () => {
  const classes = useStyles();
  return (
    <StaticQuery
      query={graphql`
        query {
          basket1: file(relativePath: { eq: "installation/basket1.png" }) {
            childImageSharp {
              fixed(width: 360, height: 640) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          basket2: file(relativePath: { eq: "installation/basket2.png" }) {
            childImageSharp {
              fixed(width: 360, height: 640) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          basket3: file(relativePath: { eq: "installation/basket3.png" }) {
            childImageSharp {
              fixed(width: 360, height: 640) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          basket4: file(relativePath: { eq: "installation/basket4.png" }) {
            childImageSharp {
              fixed(width: 360, height: 640) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          basket5: file(relativePath: { eq: "installation/basket5.png" }) {
            childImageSharp {
              fixed(width: 360, height: 640) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          basket6: file(relativePath: { eq: "installation/basket6.png" }) {
            childImageSharp {
              fixed(width: 360, height: 640) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          basket7: file(relativePath: { eq: "installation/basket7.png" }) {
            childImageSharp {
              fixed(width: 360, height: 640) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          basket8: file(relativePath: { eq: "installation/basket8.png" }) {
            childImageSharp {
              fixed(width: 360, height: 640) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          basket9: file(relativePath: { eq: "installation/basket9.png" }) {
            childImageSharp {
              fixed(width: 360, height: 640) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          basket10: file(relativePath: { eq: "installation/basket10.png" }) {
            childImageSharp {
              fixed(width: 360, height: 640) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <ManualExpansionPanel
            title={'Reseptilääkkeen tilaaminen'}
            ingress={'Miten tilaan reseptilääkkeitä apteekistani?'}
            details={
              <Grid container>
                <Grid item xs={12} sm={12} md={6} className={classes.rowPadding}>
                  <Typography>
                    1. Napauta <span className={classes.boldText}>Aloita tilauksen tekeminen</span> -painiketta
                    Minä-sivulla.
                  </Typography>
                  <Typography>
                    Vaihtoehtoisesti voit painaa Tilaa reseptilääke -paiketta Apteekki-sivulla, ja avata sen jälkeen
                    Ostoskorin.
                  </Typography>
                </Grid>
                <Grid container justify="center" item xs={12} sm={12} md={6} className={classes.rowPadding}>
                  <Img className={classes.image} fixed={data.basket1.childImageSharp.fixed} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={classes.rowPadding}>
                  <Typography>
                    2. Valitse lääkeneuvontatapa (joko chat- tai puhelinkeskustelu). Reseptilääkkeiden ostaminen
                    edellyttää apteekin antamaa lääkeneuvontaa. Anna myös apteekille lupa reseptiesi tarkasteluun.
                  </Typography>
                  <Typography>
                    Valitse lopuksi toimitustapa. Osa apteekeista tarjoaa toimitustapana kotiinkuljetuksen. Mikäli
                    valitset kotiinkuljetuksen, sinun on lisättävä toimitusosoitteesi.
                  </Typography>
                </Grid>
                <Grid container justify="center" item xs={12} sm={12} md={6} className={classes.rowPadding}>
                  <Img className={classes.image} fixed={data.basket2.childImageSharp.fixed} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={classes.rowPadding}>
                  <Typography>
                    3. Tarkasta vielä tilauksesi, ja lähetä se apteekkiin painamalla{' '}
                    <span className={classes.boldText}>Tee tilaus</span> -painiketta. Reseptilääketilausta ei makseta
                    vielä tässä vaiheessa.
                  </Typography>
                  <Typography>
                    Mikäli käytät Treet-mobiilisovellusta, Treet pyytää sinua seuraavaksi vahvistamaan henkilöllisyytesi
                    pankkitunnuksillasi tai mobiilivarmenteellasi.
                  </Typography>
                </Grid>
                <Grid container justify="center" item xs={12} sm={12} md={6} className={classes.rowPadding}>
                  <Img className={classes.image} fixed={data.basket3.childImageSharp.fixed} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={classes.rowPadding}>
                  <Typography>4. Tässä näkymässä voit seurata tilauksesi käsittelyn etenemistä.</Typography>
                  <Typography>
                    Farmaseutti ottaa sinuun pian yhteyttä valitsemallasi tavalla, antaa sinulle lääkeneuvontaa ja lisää
                    tarvitsemasi tuotteet tilaukseesi. Reseptilääkkeiden hinnat (jos sellaisia ​​on) päivittyvät
                    lääkeneuvonnan yhteydessä.
                  </Typography>
                  <Typography>
                    (Saat tämän näkymän auki myös Minä-sivulta klikkaamalla tilaustasi kohdasta “Tilaukseni”.)
                  </Typography>
                </Grid>
                <Grid container justify="center" item xs={12} sm={12} md={6} className={classes.rowPadding}>
                  <Img className={classes.image} fixed={data.basket4.childImageSharp.fixed} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={classes.rowPadding}>
                  <Typography>
                    5. Change the text to: ‘Kun apteekin henkilökunta on käsitellyt tilauksesi, saat siitä ilmoituksen
                    Treetiin ja sähköpostiisi, minkä jälkeen voit maksaa tilauksesi.
                  </Typography>
                  <Typography>
                    Paina <span className={classes.boldText}>Valitse maksutapa</span> -painiketta, ja valitse maksutapa.
                  </Typography>
                </Grid>
                <Grid container justify="center" item xs={12} sm={12} md={6} className={classes.rowPadding}>
                  <Img className={classes.image} fixed={data.basket5.childImageSharp.fixed} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={classes.rowPadding}>
                  <Typography>
                    6. Change the text to: ‘Saat Treetiin ja sähköpostiisi ilmoituksen, kun voit noutaa tilauksesi (tai
                    kun se odottaa kuljetusta luoksesi). Useat apteekit tarjoavat toimitustapavaihtoehtona
                    kotiinkuljetuksen. Kotiinkuljetukset toimitetaan arkisin tyypillisesti 3 tunnin kuluessa
                    maksamisesta.
                  </Typography>
                  <Typography>
                    Valmistaudu esittämään tilaustunnus ja henkilöllisyystodistus vastaanottaessasi tilauksen.
                  </Typography>
                </Grid>
                <Grid container justify="center" item xs={12} sm={12} md={6} className={classes.rowPadding}>
                  <Img className={classes.image} fixed={data.basket6.childImageSharp.fixed} />
                </Grid>
              </Grid>
            }
          ></ManualExpansionPanel>
        );
      }}
    />
  );
};

export default ShoppingCartSection;
