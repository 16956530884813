import React from 'react';
import { Layout } from '../components';
import { Manual } from '../components/manual';
import { Helmet } from 'react-helmet';

const ManualPage: React.FunctionComponent = () => {
  return (
    <>
      <Helmet>
        <title>Käyttöohjeet | Treet-verkkoapteekki</title>
        <meta
          name="description"
          content="Miten saan Treetin käyttööni? Miten ostan reseptilääkkeitä ja apteekkituotteita Treet-verkkoapteekista?"
        />
      </Helmet>
      <Layout>
        <Manual />
      </Layout>
    </>
  );
};

export default ManualPage;
