import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Img from 'gatsby-image';
import { makeStyles } from '@material-ui/core/styles';
import { graphql, StaticQuery } from 'gatsby';
import ManualExpansionPanel from './ManualExpansionPanel';

const useStyles = makeStyles(theme => ({
  rowPadding: {
    padding: theme.spacing(2, 0),
  },
  boldText: {
    fontWeight: 'bold',
  },
  image: {
    borderRadius: theme.spacing(2),
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
  },
}));

const ChatSection: React.FunctionComponent = () => {
  const classes = useStyles();
  return (
    <StaticQuery
      query={graphql`
        query {
          chat1: file(relativePath: { eq: "installation/chat1.png" }) {
            childImageSharp {
              fixed(width: 360, height: 640) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          chat2: file(relativePath: { eq: "installation/chat2.png" }) {
            childImageSharp {
              fixed(width: 360, height: 640) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          chat3: file(relativePath: { eq: "installation/chat3.png" }) {
            childImageSharp {
              fixed(width: 360, height: 640) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <ManualExpansionPanel
            title={'Chat- ja soittopyynnöt'}
            ingress={'Miten olen yhteydessä apteekkiini Treetin kautta?'}
            details={
              <Grid container>
                <Grid item xs={12} sm={12} md={6} className={classes.rowPadding}>
                  <Typography>
                    1. Kun olet valinnut apteekin, voit avata sen tiedot painamalla Apteekki-sivun oikeassa yläkulmassa
                    näkyvää vihreää info-painiketta.
                  </Typography>
                </Grid>
                <Grid container justify="center" item xs={12} sm={12} md={6} className={classes.rowPadding}>
                  <Img className={classes.image} fixed={data.chat1.childImageSharp.fixed} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={classes.rowPadding}>
                  <Typography>
                    2. Voit yhdellä napin painalluksella jättää apteekkiisi joko soitto- tai chat-pyynnön.
                  </Typography>
                </Grid>
                <Grid container justify="center" item xs={12} sm={12} md={6} className={classes.rowPadding}>
                  <Img className={classes.image} fixed={data.chat2.childImageSharp.fixed} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={classes.rowPadding}>
                  <Typography>
                    3. Voit jättää apteekkiisi soittopyynnön tai chat-keskustelupyynnön myös Minä-sivulla kohdassa
                    “Kysymyksiä?
                  </Typography>
                </Grid>
                <Grid container justify="center" item xs={12} sm={12} md={6} className={classes.rowPadding}>
                  <Img className={classes.image} fixed={data.chat3.childImageSharp.fixed} />
                </Grid>
              </Grid>
            }
          ></ManualExpansionPanel>
        );
      }}
    />
  );
};

export default ChatSection;
