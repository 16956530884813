import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Img from 'gatsby-image';
import { makeStyles } from '@material-ui/core/styles';
import { graphql, StaticQuery } from 'gatsby';
import { AppDownloadLinks } from '../icon';
import ManualExpansionPanel from './ManualExpansionPanel';

const useStyles = makeStyles(theme => ({
  rowPadding: {
    padding: theme.spacing(2, 0),
  },
  boldText: {
    fontWeight: 'bold',
  },
  image: {
    borderRadius: theme.spacing(2),
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
  },
}));

const InstallationSection: React.FunctionComponent = () => {
  const classes = useStyles();
  return (
    <StaticQuery
      query={graphql`
        query {
          installation1: file(relativePath: { eq: "installation/installation1.png" }) {
            childImageSharp {
              fixed(width: 360, height: 640) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          installation2: file(relativePath: { eq: "installation/installation2.png" }) {
            childImageSharp {
              fixed(width: 360, height: 640) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          installation3: file(relativePath: { eq: "installation/installation3.png" }) {
            childImageSharp {
              fixed(width: 360, height: 640) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <ManualExpansionPanel
            title={'Asennusohje'}
            ingress={'Miten saan Treetin käyttööni?'}
            details={
              <Grid container>
                <Grid item xs={12} sm={12} md={6} className={classes.rowPadding}>
                  <Typography>
                    1. Voit asioida Treet-verkkoapteekeissa osoitteessa{' '}
                    <a
                      href="https://app.treet.fi"
                      rel="noopener noreferrer"
                      target="_blank"
                      style={{ textDecoration: 'none' }}
                    >
                      Treet
                    </a>{' '}
                    tai käyttämällä Treet-mobiilisovellusta. Etsi Treet-sovellus Google Play -tai App Store -palvelusta
                    hakusanalla “Treet”, ja lataa sovellus puhelimeesi.
                  </Typography>
                </Grid>
                <Grid container justify="center" item xs={12} sm={12} md={6} className={classes.rowPadding}>
                  <AppDownloadLinks isMainImageArea />
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={classes.rowPadding}>
                  <Typography>
                    2. Avaa Treet-mobiilisovellus tai paina Aloita käyttö -painiketta Treet.fi:ssä. Yksityisyytesi
                    turvaamiseksi Treet pyytää sinua tunnistaumaan joko verkkopankkitunnuksillasi tai
                    mobiilivarmenteellasi.
                  </Typography>
                </Grid>
                <Grid container justify="center" item xs={12} sm={12} md={6} className={classes.rowPadding}>
                  <Img className={classes.image} fixed={data.installation1.childImageSharp.fixed} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={classes.rowPadding}>
                  <Typography>
                    3. Valitse apteekki, jossa tahdot asioida, hakemalla lähimpiä apteekkeja joko katuosoitteellasi tai
                    kaupungin nimellä. Huomaa, että aivan kaikki Suomen apteekit eivät vielä ole mukana Treetissä.
                  </Typography>
                </Grid>
                <Grid container justify="center" item xs={12} sm={12} md={6} className={classes.rowPadding}>
                  <Img className={classes.image} fixed={data.installation2.childImageSharp.fixed} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={classes.rowPadding}>
                  <Typography>
                    4. Treet on nyt käyttövalmis. Jotta apteekkiasiointisi olisi vaivatonta, suosittelemme lisäämään
                    vielä puhelinnumerosi, sähköpostiosoitteesi, toimitusosoitteesi ja maksukorttisi tiedot. Voit tehdä
                    sen <span className={classes.boldText}>Lisää</span>-välilehdellä kohdassa{' '}
                    <span className={classes.boldText}>Omat tiedot</span>.
                  </Typography>
                </Grid>
                <Grid container justify="center" item xs={12} sm={12} md={6} className={classes.rowPadding}>
                  <Img className={classes.image} fixed={data.installation3.childImageSharp.fixed} />
                </Grid>
              </Grid>
            }
          ></ManualExpansionPanel>
        );
      }}
    />
  );
};

export default InstallationSection;
