import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { InstallationSection, DruglistSection, ChatSection, BasketSection } from './';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(20),
  },
  installationSection: {
    margin: theme.spacing(2, 0),
  },
  manualSection: {
    margin: theme.spacing(7, 0),
  },
  rowPadding: {
    paddingTop: theme.spacing(2),
  },
  linkArrow: {
    float: 'left',
    color: 'rgba(0, 0, 0, 0.87);',
  },
  linkText: {
    fontSize: '20px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.87);',
    padding: theme.spacing(0.25),
  },
  title: {
    fontSize: '36px',
    lineHeight: '36px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(0),
  },
  divider: {
    width: '100%',
  },
}));

const Manual: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container} maxWidth="lg">
      <section className={classes.installationSection}>
        <Grid container>
          <Grid item xs={12} className={classes.rowPadding}>
            <Typography className={classes.title}>Treetin käyttöohjeet</Typography>
          </Grid>

          <Grid item xs={12} className={classes.rowPadding}>
            <InstallationSection />
          </Grid>

          <Divider className={classes.divider} />
        </Grid>
      </section>

      <section className={classes.manualSection}>
        <Grid container>
          <Grid item xs={12} className={classes.rowPadding}>
            <Typography className={classes.title}>Käyttöohjeet</Typography>
          </Grid>

          <Grid item xs={12} className={classes.rowPadding}>
            <BasketSection />
          </Grid>

          <Divider className={classes.divider} />

          <Grid item xs={12} className={classes.rowPadding}>
            <DruglistSection />
          </Grid>

          <Divider className={classes.divider} />

          <Grid item xs={12} className={classes.rowPadding}>
            <ChatSection />
          </Grid>

          <Divider className={classes.divider} />

          <Divider className={classes.divider} />
        </Grid>
      </section>
    </Container>
  );
};

export default Manual;
